<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader
        :title="$t('PageSendMoneyPepQuestions.Header').value"
        analytics-name="xe-send-money-pep-questions"
      />
    </template>

    <h2 class="type-h2 mb-4">{{ $t('PageSendMoneyPepQuestions.Title').value }}</h2>

    <p class="type-subtitle">
      {{ $t('PageSendMoneyPepQuestions.Description').value }}
    </p>

    <AppInputRadioButtons
      v-model="selectedPepAnswer"
      name="selectedPepAnswer"
      analytics-name="xe-send-money-pep-questions-radio-buttons"
      :options="availablePepAnswers"
      right
      border
    >
      <template #radio-button-content="{ option }">
        <span class="label">
          {{ option.label }}
        </span>
        <span v-if="option.labelDescription" class="option-label-description">{{
          option.labelDescription
        }}</span>
      </template>
    </AppInputRadioButtons>

    <template #footer>
      <AppCardFooter>
        <AppButton
          :loading="loadingContinue"
          analytics-name="xe-send-money-pep-questions-continue"
          :disabled="selectedPepAnswer === null"
          @click="submitPepAnswer()"
          >{{ $t('PageSendMoneyPepQuestions.ButtonContinue').value }}</AppButton
        >
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'


import { useI18nStore, useSendMoneyStore, useAnalyticsStore, useAppStore} from '@galileo/stores'

/* Components */
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import {
  useMediaQuery,
  AppButton,
  AppCard,
  AppCardFooter,
  AppInputRadioButtons,
} from '@oen.web.vue2/ui'

export default {
  name: 'SendMoneyAdditionalDetails',
  components: {
    AppCard,
    AppButton,
    AppCardFooter,
    XeStepperCardHeader,
    AppInputRadioButtons,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep

    const loadingContinue = ref(false)

    const selectedPepAnswer = ref(false)

    const availablePepAnswers = computed(() => [
      { label: $t('PageSendMoneyPepQuestions.ButtonYes').value, value: true, labelDescription: '' },
      {
        label: $t('PageSendMoneyPepQuestions.ButtonNo').value,
        value: false,
        labelDescription: $t('PageSendMoneyPepQuestions.ButtonNoDescription').value,
      },
    ])

    const submitPepAnswer = async () => {
      loadingContinue.value = true
      try {
        await sendMoneyStore.submitPepQuestion(selectedPepAnswer.value)
        await sendMoneyStore.processOrder({
          replacePage: true,
        })

        analyticsStore.track({
          event: SEGMENT_EVENTS.PEP_QUESTION_ANSWERED,
          traits: {
            property: selectedPepAnswer.value,
          },
        })
      } catch (ex) {
        appStore.logException('Exception during submit PEP question', ex)
        appStore.messageBoxGenericError()
      } finally {
        loadingContinue.value = false
      }
    }

    return {
      mq: useMediaQuery(),
      submitPepAnswer,
      loadingContinue,
      activeStep,
      $t,
      availablePepAnswers,
      selectedPepAnswer,
    }
  },
}
</script>

<style scoped></style>
